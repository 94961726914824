import React, {useState, useContext, useEffect, useRef} from "react";
import {
    Grid,
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Button,
    FormHelperText,
    Typography,
    Input,
    Checkbox,
    Link, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";
import clsx from "clsx";
import {KeyboardDateTimePicker,} from "@material-ui/pickers";
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import {LocalizationContext} from '../../../context/localizationContext';
import {emailRegex, generatePreviewImgUrl} from '../../../functional/etc';
import APICall from '../../../functional/APIRequests';
import "moment/locale/sv";
import "moment/locale/en-gb";
import {useLocation} from "react-router-dom";
import {RefContext} from '../../../context/refContext';
import {AuthContext} from '../../../context/authContext';
import {PersonelContext} from '../../../context/personelContext';
import {OrgContext} from '../../../context/orgContext';
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from 'notistack';
import {PatientContext} from "../../../context/patientContext";
import {Markup} from "interweave";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import colorConfig from "../../../styles/Theme-settings";

const ReferalFormData = props => {
        const classes = useStyles();
        const {enqueueSnackbar} = useSnackbar();
        const {loading, setLoading, user} = useContext(AuthContext);
        const {getPersonel} = useContext(PersonelContext);
        const {getTranslation, lang} = useContext(LocalizationContext);
        const {
            setReferalData,
            referalData,
            changeStateStep,
            formType,
            steps,
            activeStep,
            setValidSsn,
            formValidator,
            selectedPatientOption,
            setSelectedPatientOption,
            pnrInUse,
            setPnrInUse
        } = useContext(RefContext);
        const {patientData, setPatientData} = useContext(PatientContext);
        const {selectedOrg} = useContext(OrgContext);
        let location = useLocation();
        const [prodList, setProdList] = useState();
        const [userList, setUserList] = useState([]);
        const [userData, setUserData] = useState({
            email: '',
            name: '',
        });
        const [ssnModal, setSsnModal] = useState(false);
        const [existingSsnModal, setExistingSsnModal] = useState(false);
        const [initialExistingPatientInfo, setInitialExistingPatientInfo] = useState({name: "", pnr: ""});
        const [existingPatientInvalidPnr, setExistingPatientInvalidPnr] = useState(false);
        const [tempAutocompleteInput, setTempAutocompleteInput] = useState("");
        const [dateInfoModal, setDateInfoModal] = useState(false);
        const [patients, setPatients] = useState([]);

        const autoC = useRef(null);

        moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');

        const handleChange = (e) => {
            setReferalData({...referalData, [e.target.name]: e.target.value});
        };

        const fetchOrgPatients = (refresh = false, getAnonymized = false) => {
            let url = `customer/${selectedOrg.id}/patients?limit=999999&page=1&sort=patient.name asc&filter[archivePatient]=0`;

            if (getAnonymized) {
                url += `&filter[anonymizePatient]=1`;
            } else {
                url += `&filter[anonymizePatient]=0`;
            }

            APICall({
                setLoading,
                enqueueSnackbar,
                url: url,
                verb: 'get',
                server: 'api',
            }).then((res) => {
                if (res) {
                    setPatients([{id: 0}, ...res.data.items])
                }
                setLoading(false)
            })
        }

        const handleDateChange = (e, input) => {
            setReferalData({...referalData, [input]: e});
            if (input === "date") {
                let now = moment();
                if (now.startOf("day").unix() > moment(e).unix()) {
                    setDateInfoModal(true);
                }
            }
        }
        const handleSelectChange = (e) => {
            let selectedProd = prodList.find(product => product.sys_id === e.target.value);
            if (e.target.name === 'quickTestId') {
                setReferalData({...referalData, [e.target.name]: e.target.value, quickTestIdName: selectedProd.name})
            } else {
                setReferalData({...referalData, [e.target.name]: e.target.value})
            }
        }
        const handleBrandFilesChange = (e) => {
            let file = e.target.files[0];
            if (file) {
                if (file.size > 20971520 || file.size < 1) {
                    enqueueSnackbar(file.size > 20971520 ? getTranslation('generic_error_file_size_big') : getTranslation('generic_error_file_size_small'), {variant: 'error'})
                } else {
                    let formatList = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff'];
                    if (formatList.includes(file.type)) {
                        generatePreviewImgUrl(file, previewImgUrl => {
                            setReferalData({
                                ...referalData,
                                image: file,
                                imageBase64: previewImgUrl
                            });
                            document.getElementById('fileUpload').value = "";
                        })
                    } else {
                        enqueueSnackbar(getTranslation('generic_error_file_type'), {variant: 'error'})
                    }
                }
            }
        }

        const handleBrandFilesChangeSecundary = (e) => {
            let file = e.target.files[0];
            if (file) {
                if (file.size > 20971520 || file.size < 1) {
                    enqueueSnackbar(file.size > 20971520 ? getTranslation('generic_error_file_size_big') : getTranslation('generic_error_file_size_small'), {variant: 'error'})
                } else {
                    let formatList = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff'];
                    if (formatList.includes(file.type)) {
                        generatePreviewImgUrl(file, previewImgUrl => {
                            setReferalData({
                                ...referalData,
                                secundary_image: file,
                                secundary_imageBase64: previewImgUrl
                            });
                            document.getElementById('fileUploadSecundary').value = "";
                        })
                    } else {
                        enqueueSnackbar(getTranslation('generic_error_file_type'), {variant: 'error'})
                    }
                }
            }
        }

        const fetchData = (e) => {
            try {
                e.preventDefault()
            } catch (err) {
            }
            ;
            setLoading(true)
            APICall({
                setLoading,
                enqueueSnackbar,
                url: `quicktest`,
                verb: 'get',
                server: 'api',
            }).then((res) => {
                if (res) {
                    setProdList(res.data)
                }
                setLoading(false)
            })
        };
        const fetchPersonnel = () => {
            getPersonel('', selectedOrg.id).then(res => {
                let usersNotify = referalData.usersNotify ? JSON.parse(JSON.stringify(referalData.usersNotify)) : []
                if (!referalData.usersNotify && !usersNotify.includes(user.id)) {
                    usersNotify.push(user.id)
                }
                if (patientData && location.pathname.indexOf("filled") > -1) {
                    setReferalData(prevState => ({
                        ...prevState,
                        patient_name: patientData.name,
                        patient_pnr: patientData.pnr,
                        usersNotify: usersNotify || [],
                        patient_id: patientData.id
                    }));
                    setSelectedPatientOption(0);
                    setValidSsn(true);
                } else {
                    setReferalData({...referalData, usersNotify: usersNotify || []})
                }
                setUserList((res.data || []));
            })
        };

        const handleChangeNotSel = event => {
            setReferalData({...referalData, usersNotify: event.target.value})
        };
        const fetchProfile = (e) => {
            try {
                e.preventDefault()
            } catch (err) {
            }
            ;
            setLoading(true)
            APICall({
                setLoading,
                enqueueSnackbar,
                url: `${`${selectedOrg.id === 1 ? 'prodiagnostics' : 'customer'}`}/${selectedOrg.id}/profile`,
                verb: 'get',
                server: 'api',
            }).then((res) => {
                if (res) {
                    setUserData({
                        email: res.data.email,
                        name: res.data.name
                    })
                }
                setLoading(false)
            })
        }
        const applySelfInfo = (e) => {
            e.preventDefault();
            setReferalData({...referalData, ...userData})
        };

        const isSsnValid = (name, pnr, update = false, firstCheck = false) => {
            APICall({
                url: `customer/${selectedOrg.id}/ssn/validate?ssn=${pnr ? pnr : referalData.patient_pnr}&lang=${selectedOrg.ssn_type}`,
                verb: 'get',
                server: 'api',
            }).then(res => {
                setValidSsn(res.data);
                if (selectedPatientOption === 0) {
                    setInitialExistingPatientInfo({name: name, pnr: pnr});
                    setExistingSsnModal(!res.data);
                    if (!res.data && !firstCheck) {
                        setExistingPatientInvalidPnr(true);
                        return;
                    }
                    if (update) {
                        updatePatient();
                    }
                } else {
                    setSsnModal(!res.data);
                }
            })
        };

        const isSsnInUse = () => {
            APICall({
                url: `customer/${selectedOrg.id}/referral/patientpnrexists?pnr=${referalData.patient_pnr}&organisation_id=${selectedOrg.id}`,
                verb: "get",
                server: "api"
            })
                .then(res => {
                    setPnrInUse(res.data);
                })
        }

        const updatePatient = () => {
            let formData = new FormData();
            formData.append("data[name]", referalData.patient_name);
            formData.append("data[pnr]", referalData.patient_pnr);

            APICall({
                enqueueSnackbar,
                url: `customer/${selectedOrg.id}/patients/${referalData.patient_id}`,
                verb: "post",
                server: "api",
                data: formData
            })
                .then(res => {
                    if (res) {
                        setReferalData({...referalData, ["patient_pnr"]: res.data.pnr, ["patient_name"]: res.data.name});
                        fetchOrgPatients(true, false);
                        setExistingSsnModal(false);
                        setExistingPatientInvalidPnr(false)
                    }
                })
        };

        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            limit: 200,
        });

        const handleCloseExistingSsnModal = () => {
            setTempAutocompleteInput("");
            setReferalData({
                ...referalData,
                ["patient_name"]: "",
                ["patient_pnr"]: "",
                ["patient_id"]: ""
            });
            const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();
            setExistingSsnModal(false);
        };

        const getSsnModalContent = () => {
            let content = {title: "", text: ""};

            if (selectedOrg.perm === 1) {
                switch (selectedOrg.ssn_type) {
                    case "se":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_admin_se");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_admin_se");
                        break;
                    case "dk":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_admin_dk");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_admin_dk");
                        break;
                    case "fi":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_admin_fi");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_admin_fi");
                        break;
                }
            } else {
                switch (selectedOrg.ssn_type) {
                    case "se":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_user_se");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_user_se");
                        break;
                    case "dk":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_user_dk");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_user_dk");
                        break;
                    case "fi":
                        content.title = getTranslation("referal_form_invalid_ssn_modal_title_user_fi");
                        content.text = getTranslation("referal_form_invalid_ssn_modal_content_user_fi");
                        break;
                    default:
                        break;
                }
            }
            return content;
        }

        const getExistingSsnModalContent = () => {
            let translationKey = '';
            if (selectedOrg.perm === 1) {
                switch (selectedOrg.ssn_type) {
                    case "se":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_admin_se';
                        break;
                    case "dk":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_admin_dk';
                        break;
                    case "fi":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_admin_fi';
                        break;
                }
            } else {
                switch (selectedOrg.ssn_type) {
                    case "se":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_user_se';
                        break;
                    case "dk":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_user_dk';
                        break;
                    case "fi":
                        translationKey = 'referal_form_existing_patient_invalid_ssn_modal_content_user_fi';
                        break;
                }
            }
            return getTranslation(translationKey);
        }

        useEffect(() => {
            if (formValidator() && !steps[activeStep].valid) {
                setLoading(true)
                changeStateStep('validChange', 2, true)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            } else if (!formValidator() && !!steps[activeStep].valid) {
                setLoading(true)
                changeStateStep('validChange', 2, false)
                setTimeout(() => {
                    setLoading(false)
                }, 1);
            }
            // eslint-disable-next-line
        }, [referalData])
        useEffect(() => {
            if (formType === 2) {
                fetchData()
            }
            fetchPersonnel();
            fetchProfile();
            // eslint-disable-next-line
        }, []);

        useEffect(() => {
            if (Number(selectedOrg.patientmodule) === 1)
                fetchOrgPatients(true, false);
        }, []);

        return (
            <>
                <Grid container>
                    {formType === 2 &&
                        <React.Fragment>
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('referal_form_step_tree_title_warranty')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className={`fixSelectAndDates ${classes.formControl}`}
                                             error={
                                                 !referalData.quickTestId ||
                                                 (referalData.quickTestId && referalData.quickTestId.length === 0)
                                             }>
                                    <InputLabel
                                        id="quickTestLabel">{getTranslation('warranty_report_form_test_type')}</InputLabel>
                                    <Select
                                        labelId="quickTestLabel"
                                        // disabled={loading}
                                        value={referalData.quickTestId || ''}
                                        name='quickTestId'
                                        onChange={handleSelectChange}
                                        error={!referalData.quickTestId || (referalData.quickTestId && referalData.quickTestId.length === 0)}
                                    >
                                        <MenuItem disabled={true}
                                                  value={''}>{getTranslation('generic_select_one')}</MenuItem>
                                        {prodList &&
                                            prodList.map(item => {
                                                return <MenuItem value={item.sys_id}
                                                                 key={item.sys_id}>{item.name}</MenuItem>
                                            })}
                                    </Select>
                                    {(!referalData.quickTestId || (referalData.quickTestId && referalData.quickTestId.length === 0)) &&
                                        <FormHelperText>{getTranslation('generic_error_required_field')}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <KeyboardDateTimePicker
                                    className='fixSelectAndDates'
                                    format="YYYY-MM-DD HH:mm"
                                    autoOk
                                    InputAdornmentProps={{position: "start"}}
                                    label={getTranslation('warranty_report_form_test_date')}
                                    // disabled={loading}
                                    value={referalData.dateWarranty || null}
                                    onChange={(e) => {
                                        if (e.isValid()) {
                                            handleDateChange(e, 'dateWarranty')
                                        } else {
                                            handleDateChange(null, 'dateWarranty')

                                        }
                                    }}
                                    error={!referalData.dateWarranty || (referalData.dateWarranty && referalData.dateWarranty.length === 0)}
                                    helperText={(!referalData.dateWarranty || (referalData.dateWarranty && referalData.dateWarranty.length === 0)) ? getTranslation('generic_error_required_field') : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="filled"
                                    label={getTranslation('warranty_report_form_test_id')}
                                    name='warrantyLotId'
                                    // disabled={loading}
                                    value={referalData.warrantyLotId}
                                    onChange={handleChange}
                                    error={!referalData.warranty || (referalData.warranty && referalData.warranty.length === 0)}
                                    helperText={(!referalData.warrantyLotId || (referalData.warrantyLotId && referalData.warrantyLotId.length === 0)) ? getTranslation('generic_error_required_field') : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    label={getTranslation('warranty_report_form_test_description')}
                                    name='warrantyComments'
                                    multiline
                                    rowsMax="6"
                                    // disabled={loading}
                                    value={referalData.warrantyComments}
                                    onChange={handleChange}
                                    error={false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{padding: 10}}>
                                <Button
                                    name="image"
                                    disabled={loading}
                                    className={classes.cartButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        document.getElementById('fileUpload').click();
                                    }}
                                >
                                    {getTranslation('warranty_report_form_button_upload')}
                                </Button>
                                {!referalData.imageBase64 &&
                                    <Typography variant="body2"
                                                className={classes.imageErrorText}>{getTranslation('generic_error_required_field')}</Typography>
                                }
                                <input id='fileUpload' onChange={handleBrandFilesChange} hidden type='file'
                                       accept=".jpg,.jpeg,.png,.gif,.tiff"/>
                            </Grid>
                            <Grid item xs={6} style={{padding: 10}}>
                                <Button
                                    name="image"
                                    disabled={loading}
                                    className={classes.cartButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        document.getElementById('fileUploadSecundary').click();
                                    }}
                                >
                                    {getTranslation('warranty_report_form_button_upload_secundary')}
                                </Button>
                                <input id='fileUploadSecundary' onChange={handleBrandFilesChangeSecundary} hidden
                                       type='file'
                                       accept=".jpg,.jpeg,.png,.gif,.tiff"/>
                            </Grid>
                            {referalData.imageBase64 &&
                                <Grid container justify='center' xs={6}>
                                    <div className={classes.imageContainer}
                                         style={{backgroundImage: `url("${referalData.imageBase64}")`}}/>
                                </Grid>
                            }
                            {referalData.secundary_imageBase64 &&
                                <Grid container justify='center' xs={6}>
                                    <div className={classes.imageContainer}
                                         style={{backgroundImage: `url("${referalData.secundary_imageBase64}")`}}/>
                                </Grid>
                            }
                        </React.Fragment>
                    }
                    <Grid item xs={12} style={{marginTop: 20}}>
                        <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                            {getTranslation('referal_form_data_step_tree_title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link href="#" onClick={applySelfInfo} variant="caption">
                            {getTranslation('referal_form_notification_useSelf')}
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="filled"
                            label={getTranslation('referal_form_label_tester_name')}
                            name='name'
                            disabled={loading}
                            value={referalData.name || ''}
                            onChange={handleChange}
                            error={!referalData.name || (referalData.name && referalData.name.length === 0)}
                            helperText={!referalData.name || referalData.name.length === 0 ? getTranslation('generic_error_required_field') : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="filled"
                            label={getTranslation('referal_form_label_email')}
                            error={!referalData.email || (referalData.email.length > 0 && !emailRegex.test(referalData.email))}
                            name='email'
                            // disabled={loading}
                            value={referalData.email || ''}
                            onChange={handleChange}
                            type='email'
                            helperText={!referalData.email || referalData.email.length === 0 ? getTranslation('generic_error_required_field') : referalData.email.length > 0 && !emailRegex.test(referalData.email) ? getTranslation('generic_email_regex_fail') : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className='fixSelectAndDates'>
                            <InputLabel>{getTranslation('referal_form_label_notifications')}</InputLabel>
                            <Select
                                disabled={loading}
                                value={referalData.usersNotify || []}
                                multiple={true}
                                onChange={handleChangeNotSel}
                                input={<Input/>}
                                renderValue={selected => {
                                    let renderV = [];
                                    selected.forEach(s => {
                                        if (userList) {
                                            let tempHold = userList.find(u => u.sys_id === s);
                                            if (tempHold) {
                                                renderV.push(tempHold.name)
                                            }
                                        }
                                    })
                                    return renderV.join(', ')
                                }}
                            >
                                {userList &&
                                    userList.map((u, i) => {
                                        // if (u.sys_id !== user.id) {
                                        return <MenuItem key={u.sys_id} value={u.sys_id}>
                                            <Checkbox checked={
                                                referalData.usersNotify ? referalData.usersNotify.findIndex(nu => nu === u.sys_id) > -1 : false
                                            }
                                            />
                                            <div style={{float: "left"}}>
                                                {u.name}
                                            </div>
                                        </MenuItem>
                                        // }
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {Number(selectedOrg.patientmodule) === 1 &&
                        <>
                            <Grid item xs={12} sm={6}>
                                <div>
                                    <FormControl component="fieldset" className={classes.radioFormControl}>
                                        <RadioGroup row aria-label="patient" name="patient" value={selectedPatientOption}
                                                    onChange={(e) => {
                                                        if (Number(e.target.value) === 0) {
                                                            fetchOrgPatients(true, false);
                                                        }
                                                        setReferalData({
                                                            ...referalData,
                                                            ["patient_name"]: "",
                                                            ["patient_pnr"]: "",
                                                            ["patient_id"]: ""
                                                        })
                                                        setSelectedPatientOption(Number(e.target.value));
                                                        setValidSsn(false);
                                                    }}
                                                    className={classes.radioGroup}
                                        >
                                            <FormControlLabel value={0} control={<Radio/>}
                                                              label={getTranslation("referral_form_existing_patient_checkbox")}
                                                              style={{marginRight: 20}}/>
                                            <FormControlLabel value={1} control={<Radio/>}
                                                              label={getTranslation("referral_form_new_patient_checkbox")}/>
                                        </RadioGroup>
                                    </FormControl>
                                    {(selectedPatientOption === 0 && patients.length > 0) &&
                                        <Autocomplete
                                            ref={autoC}
                                            classes={{
                                                option: classes.autocompleteOption,
                                                listbox: !tempAutocompleteInput ? classes.listboxAutocomplete : ""
                                            }}
                                            options={patients}
                                            filterOptions={filterOptions}
                                            value={patients.find(p => p.id === referalData.patient_id)}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setReferalData({
                                                        ...referalData,
                                                        ["patient_name"]: newValue.name,
                                                        ["patient_pnr"]: newValue.pnr,
                                                        ["patient_id"]: newValue.id
                                                    });
                                                    isSsnValid(newValue.name, newValue.pnr, false, true);
                                                }
                                            }}
                                            renderInput={params => <TextField {...params}
                                                                              label={getTranslation("referral_form_new_patient_dropdown_label")}
                                                                              variant="filled"
                                                                              value={tempAutocompleteInput}
                                                                              onChange={e => setTempAutocompleteInput(e.target.value)}
                                                                              error={!referalData.patient_pnr || !referalData.patient_name || ((referalData.patient_pnr && referalData.patient_pnr.length === 0) || (referalData.patient_name && referalData.patient_name.length === 0))}
                                                                              helperText={!referalData.patient_pnr || !referalData.patient_name || referalData.patient_pnr.length === 0 || referalData.patient_name.length === 0 ? getTranslation('generic_error_required_field') : ''}
                                            />}
                                            getOptionSelected={(option, value) => option.id !== 0 && option.id === value.id}
                                            renderOption={(selected) => (
                                                <>
                                                    {selected.id === 0 ?
                                                        <Grid container>
                                                            <Grid item xs={6} className="referral-patient-lab.el-option">
                                                                <b>{getTranslation("referral_form_existing_patient_name_label")}</b>
                                                            </Grid>
                                                            <Grid item xs={6} className="referral-patient-label-option">
                                                                <b>{getTranslation("referral_form_existing_patient_pnr_label")}</b>

                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                {selected.name}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {selected.pnr}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                            getOptionLabel={(selected) =>
                                                (referalData.patient_name && referalData.patient_pnr) ? `${referalData.patient_name}  ${referalData.patient_pnr}` : (selected ? `${selected.name}  ${selected.pnr}` : selected)
                                            }
                                        />
                                    }
                                    {selectedPatientOption === 1 &&
                                        <TextField
                                            variant="filled"
                                            label={getTranslation('referal_form_label_patient_name')}
                                            name='patient_name'
                                            // disabled={loading}
                                            value={referalData.patient_name || ''}
                                            onChange={handleChange}
                                            error={!referalData.patient_pnr || (referalData.patient_pnr && referalData.patient_pnr.length === 0)}
                                            helperText={!referalData.patient_pnr || referalData.patient_pnr.length === 0 ? getTranslation('generic_error_required_field') : ''}
                                        />
                                    }
                                </div>
                            </Grid>
                            {
                                selectedPatientOption === 1 &&
                                <Grid item xs={6} sm={3}>
                                    <TextField
                                        variant="filled"
                                        label={getTranslation('referal_form_label_patient_ssn')}
                                        name='patient_pnr'
                                        // disabled={loading}
                                        className={classes.patientPnrInputWithAdvancedModule}
                                        value={referalData.patient_pnr || ''}
                                        onChange={handleChange}
                                        onBlur={() => {
                                            if (referalData.patient_pnr) {
                                                isSsnValid("", "", false, false);
                                                isSsnInUse();
                                            }
                                        }}
                                        error={!referalData.patient_pnr || (referalData.patient_pnr && referalData.patient_pnr.length === 0)}
                                        helperText={!referalData.patient_pnr || referalData.patient_pnr.length === 0 ? getTranslation('generic_error_required_field') : (pnrInUse ? getTranslation("referal_form_ssn_used") : '')}
                                    />
                                </Grid>
                            }
                        </>
                    }
                    {Number(selectedOrg.patientmodule) === 0 &&
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="filled"
                                label={getTranslation('referal_form_label_patient')}
                                name='initials'
                                // disabled={loading}
                                value={referalData.initials || ''}
                                onChange={handleChange}
                                inputProps={{maxLength: 5}}
                                error={!referalData.initials || (referalData.initials && referalData.initials.length === 0)}
                                helperText={!referalData.initials || referalData.initials.length === 0 ? getTranslation('generic_error_required_field') : ''}
                            />
                        </Grid>
                    }
                    <Grid item xs={6}
                          sm={Number(selectedOrg.patientmodule) === 0 ? 6 : (selectedPatientOption === 0 ? 6 : 3)}>
                        <KeyboardDateTimePicker
                            className='fixSelectAndDates'
                            style={Number(selectedOrg.patientmodule) === 1 ? (selectedPatientOption === 1 ? {marginTop: 45} : {marginTop: 46}) : {}}
                            format="YYYY-MM-DD HH:mm"
                            label={getTranslation('referal_form_label_sample_date')}
                            ampm={false}
                            autoOk
                            InputAdornmentProps={{position: "start"}}
                            // disabled={loading}
                            value={referalData.date || null}
                            onChange={(e) => {
                                if (e && e.isValid()) {
                                    handleDateChange(e, 'date')
                                } else {
                                    handleDateChange(null, 'date')
                                }
                            }}
                            error={!referalData.date || (referalData.date && referalData.date.length === 0)}
                            helperText={!referalData.date || referalData.date.length === 0 ? getTranslation('generic_error_required_field') : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="filled"
                            label={getTranslation('referal_form_label_drug_history')}
                            name='historic'
                            multiline
                            rowsMax="4"
                            // disabled={loading}
                            value={referalData.historic}
                            onChange={handleChange}
                            error={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="filled"
                            label={getTranslation('referal_form_label_comments')}
                            name='labComments'
                            multiline
                            rowsMax="4"
                            // disabled={loading}
                            value={referalData.labComments}
                            onChange={handleChange}
                            error={false}
                        />
                    </Grid>
                </Grid>
                <Dialog open={ssnModal} onClose={() => setSsnModal(false)}>
                    <DialogTitle>
                        <Markup
                            content={getSsnModalContent().title}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Markup
                            content={getSsnModalContent().text}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setSsnModal(false)}
                        >
                            {getTranslation('referal_form_invalid_ssn_modal_ok_button')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={existingSsnModal} onClose={handleCloseExistingSsnModal} maxWidth="md">
                    <DialogContent className={classes.existingPatientModalContent}>
                        <Grid container>
                            <Grid item xs={6} style={{display: "flex", justifyContent: "space-evenly"}}>
                                <div>
                                    <Typography variant="h6"
                                                className={classes.modalSectionTitle}>{getTranslation("referal_form_modal_patient_info")}</Typography>
                                    <Typography
                                        variant="subtitle2">{getTranslation('referal_form_existing_patient_modal_name')}</Typography>
                                    <TextField
                                        variant="outlined"
                                        name='patient_name'
                                        value={referalData.patient_name || ''}
                                        onChange={handleChange}
                                        className={clsx(classes.updatePatientInput, classes.updatePatientInputName)}
                                    />
                                    <Typography
                                        variant="subtitle2">{getTranslation('referal_form_existing_patient_modal_pnr')}</Typography>
                                    <TextField
                                        variant="outlined"
                                        name='patient_pnr'
                                        value={referalData.patient_pnr || ''}
                                        onChange={handleChange}
                                        className={classes.updatePatientInput}
                                    />
                                    {existingPatientInvalidPnr &&
                                        <Typography variant="body2"
                                                    className={classes.invalidPnrText}>{getTranslation('referal_form_existing_patient_modal_invalid_pnr_text')}</Typography>
                                    }

                                    <Button
                                        variant="contained"
                                        onClick={() => isSsnValid("", "", true, false)}
                                        fullWidth
                                        className={classes.updatePatientBtn}
                                    >
                                        {getTranslation("referal_form_modal_update_patient_button")}
                                    </Button>
                                </div>
                                <div className={classes.divider}></div>
                            </Grid>

                            <Grid item xs={6} className={classes.infoWrap}>
                                <Typography variant="h6"
                                            className={classes.modalSectionTitle}>{getTranslation("referal_form_modal_info")}</Typography>
                                <Markup
                                    content={getExistingSsnModalContent()}
                                />
                                <CloseIcon className={classes.cancelModalIcon} onClick={handleCloseExistingSsnModal}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog open={dateInfoModal} onClose={() => setDateInfoModal(false)} maxWidth="md">
                    <DialogTitle>
                        {getTranslation("referal_form_date_modal_header")}
                    </DialogTitle>
                    <DialogContent>
                        {getTranslation("referal_form_date_modal_content")}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setDateInfoModal(false)}
                        >
                            {getTranslation('referal_form_date_modal_ok_button')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
;
const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        width: "100%",
        padding: 20
    },
    cartButton: {
        width: '100%',
        // margin: '5px 0'
        height: '100%'
    },
    selectTitle: {
        width: '100%',
        marginTop: 20,
    },
    selectGroup: {
        padding: 10
    },
    imageContainer: {
        height: 200, width: 200,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    imageErrorText: {
        color: "#ff1744",
        fontSize: 12,
        fontWeight: 400
    },
    patientPnrInputWithAdvancedModule: {
        marginTop: 45,
        "& .MuiFormHelperText-root": {
            color: "#ff1744"
        }
    },
    radioFormControl: {
        paddingBottom: 0
    },
    radioGroup: {
        padding: "0px 10px"
    },
    autocompleteOption: {
        "& .referral-patient-label-option": {
            color: colorConfig.primary,
            cursor: "default",
        },
    },
    listboxAutocomplete: {
        "& > *:first-child": {
            backgroundColor: "white !important",
            pointerEvents: "none",
            "&:hover": {
                backgroundColor: "white !important"
            },
            "&:focus": {
                backgroundColor: "white !important"
            }
        },
        '& > *:nth-child(n+2)': {
            padding: "4px 16px"
        }
    },
    updatePatientBtn: {
        background: "#588d2d",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "rgba(88, 141, 45, 0.9)"
        },
    },
    updatePatientInput: {
        padding: "4px 0px 10px 0px"
    },
    updatePatientInputName: {
        marginBottom: 10
    },
    modalSectionTitle: {
        marginBottom: 20
    },
    infoWrap: {
        position: "relative",
        paddingLeft: "24px !important",
        marginBottom: 30
    },
    existingPatientModalContent: {
        overflowY: "hidden"
    },
    cancelModalIcon: {
        position: "absolute",
        top: 10,
        right: 10,
        cursor: "pointer",
        height: 24,
        width: 24,
        fill: "#707070"
    },
    invalidPnrText: {
        color: "#ff0000",
        marginBottom: 5,
        marginTop: "-5px"
    },
    divider: {
        borderRight: "1px solid #DCDCDC",
        marginLeft: 24,
        marginBottom: 15
    },

}));
export default ReferalFormData;
