import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, LinearProgress, Button, ButtonGroup, } from "@material-ui/core";
import { LocalizationContext } from '../context/localizationContext';
import { PersonelContext } from '../context/personelContext';
import { AuthContext } from '../context/authContext';
import moment from 'moment';
import Table from './Table/index';
import { FilterList, Add, } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from './SeachDialog';
import SearchChips from './SearchChips';

const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  }
}));
const StaffTable = (props) => {
  const { loading } = useContext(AuthContext);
  const { getTranslation, lang } = useContext(LocalizationContext);
  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sort, setSort] = useState({ column: 'name', by: 'asc' })
  const [searchState, setSearchState] = useState(false)
  const [filter, setFilter] = useState({})
  const [data, setData] = useState([]);
  const { getPersonel } = useContext(PersonelContext);
  const [count, setCount] = useState(0);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    setSort({ column: column, by: order })
  }
  const generetaQueryString = () => {
    let queryString = `?sort=${sort.column} ${sort.by}`;
    queryString += `&limit=${rowsPerPage}&offset=${rowsPerPage * page}`
    Object.keys(filter).forEach(f => {
      queryString += `&filter[${f}]=${filter[f]}`
    })
    return queryString;
  }
  const columns = [
    //Name
    {
      name: getTranslation('personel_table_header_name'),
      functionalName: 'name',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //PNR
    {
      name: getTranslation('personel_table_header_personal_id'),
      functionalName: 'pnr',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: false,
      align: 'left',
    },
    //Email
    {
      name: getTranslation('personel_table_header_email'),
      functionalName: 'email',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Phone
    {
      name: getTranslation('personel_table_header_phone'),
      functionalName: 'cellphone',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Reg Date
    // {
    //   name: getTranslation('personel_table_header_roll'),
    //   functionalName: 'title',
    //   type: 'text',
    //   filter: true,
    //   filterOptions: {
    //     col: 6,
    //   },
    //   sortable: true,
    //   visible: true,
    //   align: 'left',
    // },
    //HSAID
    {
      name: getTranslation('personel_table_header_hsaid'),
      functionalName: 'hsaid',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'center',
    },
    //Status
    {
      id: 8,
      name: getTranslation('generic_table_header_status'),
      functionalName: 'status',
      type: 'select',
      list: [
        { id: 1, text: 'Aktiv' },
        { id: 0, text: 'Ej aktiv' },
      ],
      multi: true,
      filter: true,
      sortable: true,
      visible: true,
      align: 'left',
    },
  ]

  const fetchData = () => {
    getPersonel(generetaQueryString(), props.id).then(res => {
      if (res) {
        if (Number(res.total_records) > 0 && res.data.length > 0) {
          setCount(Number(res.total_records) || 0);
        }
        setData((res.data || []).map(data => {
          return {
            ...data,
            status: {
              color: !data.deactivated_at ? '#28A745' : '#D8102E',
              name: !data.deactivated_at ? getTranslation('generic_table_string_active') : getTranslation('generic_table_string_inactive')
            }
          };
        }))
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => fetchData(), [filter, sort, page, rowsPerPage, props.id])
  // eslint-disable-next-line
  useEffect(() => { if (page > 0) { setPage(0) } }, [filter])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.pageHeader}>
          <ButtonGroup size="small" color="primary">
            <Button startIcon={<Add />} onClick={() => props.history.push(`${props.match.params.id}/personel/new`)} > {getTranslation('generic_button_add')} </Button>
            {/*<Button startIcon={<FilterList />} onClick={() => setSearchState(true)} > {getTranslation('generic_button_filter')} </Button>*/}
          </ButtonGroup>
        </Typography>
        {Object.keys(filter).length > 0 &&
          <SearchChips
            filter={filter}
            setFilter={setFilter}
            columns={columns} />
        }
        {loading && <LinearProgress />}
        <Table
          count={count}
          rows={data}
          page={page}
          columns={columns}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortChange={handleSortChange}
          sort={sort}
          onLineClick={`${props.match.params.id}/personel/`}
        />
        <Dialog
          filter={filter}
          setFilter={setFilter}
          open={searchState}
          onClose={setSearchState}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
}

export default StaffTable;
