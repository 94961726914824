import React, {useEffect, useState} from "react";
import {BrowserMultiFormatReader, NotFoundException} from '@zxing/library';
import {Grid, Select, MenuItem} from "@material-ui/core";

const BarcodeReader = props => {
    const {
        handleClose,
        handleCapture
    } = props;

    const [selectedCamera, setSelectedCamera] = useState("");

    const getBackCamerasWithZoom = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(
                (device) => device.kind === "videoinput"
            );

            // Filter back-facing cameras based on the label
            const backFacingCameras = videoDevices.filter((device) => device.label.toLowerCase().includes("back") || device.label.toLowerCase().includes("environment"));

            if (backFacingCameras.length === 0) {
                setSelectedCamera(videoDevices[0].deviceId);
                return;
            }

            let cameraWithMaxZoom = null;
            let maxZoomValue = 1;

            // Check zoom capabilities for each back camera
            for (let camera of backFacingCameras) {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: { deviceId: { exact: camera.deviceId } },
                });

                const videoTrack = stream.getVideoTracks()[0];
                const capabilities = videoTrack.getCapabilities();

                // If the camera supports zoom, check the max zoom value
                if (capabilities.zoom) {
                    const currentMaxZoom = capabilities.zoom.max;
                    if (currentMaxZoom > maxZoomValue) {
                        maxZoomValue = currentMaxZoom;
                        cameraWithMaxZoom = camera;
                    }
                }

                // Stop the video track after checking capabilities
                videoTrack.stop();
            }

            if (cameraWithMaxZoom) {
                setSelectedCamera(cameraWithMaxZoom.deviceId);
            } else {
                // Fallback to the first available camera if none support zoom
                setSelectedCamera(backFacingCameras[0].deviceId);
            }
        } catch (err) {
            console.error("Error accessing devices:", err);
        }
    }

    const startScanner = async () => {
        const codeReader = new BrowserMultiFormatReader();
        try {
            await codeReader.decodeFromVideoDevice(
                selectedCamera,
                "video",
                (result, err) => {
                    if (result) {
                        handleCapture(result.text);
                        codeReader.reset(); // Stop scanning after barcode detection
                        handleClose();
                    }
                    if (err && !(err instanceof NotFoundException)) {
                        console.error(err);
                    }
                }
            );
        } catch (err) {
            console.error("Error starting the scanner:", err);
        }
    };

    useEffect(() => {
        getBackCamerasWithZoom();
    }, []);

    useEffect(() => {
        if(selectedCamera) {
            startScanner();
        }
    }, [selectedCamera])

    return (
        <Grid item xs={12}>
            <video id="video" width="300" height="225"></video>
        </Grid>
    )
}

export default BarcodeReader;