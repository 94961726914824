import React, {useContext, useEffect, useState, Fragment} from "react";
import {
    Grid,
    Paper,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Switch,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    Dialog as ProfileDialog,
    DialogTitle,
    DialogContent,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Launch, ArrowBackIos} from '@material-ui/icons'
import PersonelTable from "../../components/PersonelTable";
import {OrgContext} from "../../context/orgContext";
import {LocalizationContext} from '../../context/localizationContext';
import {permLevel} from '../../functional/etc';
import colors from '../../styles/Theme-settings';
import Profile from '../Profile';
import Address from './organization-address';
import {countries} from "../../functional/etc";

const useStyles = makeStyles(theme => ({
    container: {
        flexDirection: "row",
        justifyContent: "center",
        display: "flex"
    },
    paper: {
        padding: 20,
        width: "100%",
        '&:not(:last-child)': {
            borderBottom: '1px solid #F5F5F5',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    paperTitle: {
        width: "100%",
        padding: 20,
        marginBottom: 20,
    },
    fakeLink: {
        color: colors.primary,
        cursor: 'pointer',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
    },

}));
const OrganizationPage = props => {
    const classes = useStyles();
    const [data, setData] = useState({name: null, org: null, mainAdress: [], deliverryAdress: [], ssn_type: ""});
    const [active, setActive] = useState(false);
    const [TFA, setTFA] = useState(false);
    const {
        getOrganizationById,
        selectedOrg,
        setSelectedOrg,
        patchStatusOrganization,
        patchAuthTypeOrganization,
        wacomCheck,
        toogleWacom,
        setLoading,
        APICall,
        enqueueSnackbar
    } = useContext(OrgContext)
    let selectedOrgId = !isNaN(props.match.params.id) ? Number(props.match.params.id) : Number(selectedOrg.id);
    let prodManaging = Number(selectedOrg.id) === 1 && !isNaN(props.match.params.id);
    const {availableLangs, lang, changeLang, getTranslation} = useContext(LocalizationContext);
    const [open, setOpen] = useState(false);

    const [infoSolutions, setInfoSolutions] = useState({customerId: '', autoSync: false, open: false});

    document.title = getTranslation('settings_browser_title') + ' | Prodiagnostics';

    const fetchData = () => {
        if (Number(selectedOrgId) !== 1) {
            getOrganizationById({idOrg: selectedOrgId}).then(res => {
                if (res) {
                    setTFA(res.allow_twofactor === '1' ? true : false)
                    setActive(!res.deactivated_at)
                    setData({
                        ...res,
                        mainAdress: [res.address, res.address2, res.postal_code, res.city, res.contact_phone, res.email, res.www, res.sys_id],
                        ssn_type: res.ssn_type || "se"
                    });
                    setInfoSolutions({
                        customerId: res.infosolutions_tosystemid,
                        autoSync: res.autosync === "1",
                        open: !!res.infosolutions_tosystemid
                    });
                } else {
                    window.history.back()
                }
            })
        }
    }

    const getPerm = () => {
        return permLevel(selectedOrg)
    }
    const changeStatus = (e) => {
        setActive(e.target.checked)
        patchStatusOrganization({idOrg: props.match.params.id, status: e.target.checked})
    }
    const saveAuthTypeChanges = (value) => {
        patchAuthTypeOrganization({idOrg: props.match.params.id, TFA: value})
    }
    const toogleInfoSolutions = () => {
        if (infoSolutions.open) {
            handleInfoSolutionsChange(selectedOrgId, null);
        }
        setInfoSolutions({...infoSolutions, open: !infoSolutions.open});
    }

    const handleInfoSolutionsChange = (orgId, e) => {
        setLoading(true);
        let formData = new FormData();

        if (e === null) {
            formData.append("data[infosolutions_tosystemid]", '');
            formData.append("data[autosync]", '0');
            setInfoSolutions({customerId: '', autoSync: false});
        } else if (e.target.name === 'customer_id') {
            formData.append("data[infosolutions_tosystemid]", e.target.value);
            setInfoSolutions({...infoSolutions, customerId: e.target.value});
        } else if (e.target.name === 'auto_sync') {
            formData.append("data[autosync]", !infoSolutions.autoSync ? 1 : 0);
            setInfoSolutions({...infoSolutions, autoSync: !infoSolutions.autoSync});
        }

        return APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `prodiagnostics/1/customer/${orgId}`,
            verb: 'POST',
            data: formData,
            server: 'api',
        }).then((res) => {
            setLoading(false);
        })
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }
    const submitData = (e) => {
        try {
            e.preventDefault()
        } catch (err) {
        }
        ;
        if (!!data.newLang) {
            changeLang(data.newLang)
        }
    }
    const handleBack = () => {
        props.history.goBack();
    };

    const togglePatient = e => {
        setLoading(true);

        setData({...data, ["patientmodule"]: e.target.checked ? 1 : 0});

        let formData = new FormData();
        formData.append("data[patientmodule]", e.target.checked ? 1 : 0);

        APICall({
            setLoading,
            enqueueSnackbar,
            formData: true,
            url: `prodiagnostics/${selectedOrg.id}/customer/${data.sys_id}`,
            verb: "post",
            server: "api",
            data: formData
        })
            .then(res => {
                if (res) {
                    enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    const handleSaveCountry = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("data[ssn_type]", data.ssn_type);

        APICall({
            setLoading,
            enqueueSnackbar,
            formData: true,
            url: `prodiagnostics/${selectedOrg.id}/customer/${data.sys_id}`,
            verb: "post",
            server: "api",
            data: formData
        })
            .then(res => {
                console.log(res);
                if (res) {
                    setSelectedOrg({...selectedOrg, ssn_type: data.ssn_type});
                    enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
                }
                setLoading(false);
            })
            .catch(() => setLoading(false))
    };

    const handleCustomerHidePatientClick = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("data[patientmodule]", 0);

        APICall({
            setLoading,
            enqueueSnackbar,
            formData: true,
            url: `prodiagnostics/${selectedOrg.id}/customer/${data.sys_id}`,
            verb: "post",
            server: "api",
            data: formData
        })
            .then(res => {
                if (res) {
                    setData({...data, patientmodule: 0})
                    setSelectedOrg({...selectedOrg, patientmodule: 0});
                    enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrgId])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} sm={10}>
                <Paper className={classes.paperTitle} elevation={1}>
                    {props.match.params.id !== 'settings' &&
                        <Button
                            style={{margin: '0px 5px', float: 'right'}}
                            variant="outlined"
                            color="primary"
                            onClick={handleBack}
                            startIcon={<ArrowBackIos style={{height: 12, width: 12}}/>}
                        >{getTranslation('generic_button_back_to_list')}</Button>
                    }
                    <Typography variant="h6" gutterBottom>
                        {getTranslation('my_page_title')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {getTranslation('my_page_subtitle')}
                    </Typography>
                </Paper>
                {selectedOrgId !== 1 &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_address_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_address_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>

                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_customer_number')}</b> {data.customer_number}
                                </Typography>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_company')}</b> {data.name}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_address')}</b> {data.address}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_zipcode')}</b> {data.postal_code}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_city')}</b> {data.city}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_customer_id')}</b> {data.orgnr}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_phone')}</b> {data.contact_phone}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_email')}</b> {data.email}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    <b>{getTranslation('organization_info_cart_title_www')}</b> {data.www}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {selectedOrgId !== 1 && (getPerm() > 3) &&
                                    <Grid item xs={12}>
                                        <FormGroup row style={{float: 'right', padding: 0}}>
                                            <FormLabel
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    paddingRight: 10,
                                                    fontSize: 14
                                                }}
                                                component="legend"
                                            >
                                                {getTranslation('generic_string_active')}
                                            </FormLabel>
                                            <Switch
                                                checked={active}
                                                onChange={changeStatus}
                                                color="primary"
                                            />
                                        </FormGroup>
                                    </Grid>
                                }
                            </Grid>
                            {selectedOrgId !== 1 &&
                                <Grid item xs={12}>
                                    <Typography className={classes.fakeLink} variant="body2" gutterBottom
                                                onClick={(e) => {
                                                    setOpen('2')
                                                }}>
                                        {getTranslation('my_page_address_change_request')}<Launch
                                        style={{marginLeft: 5}}/>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Paper>
                }
                {selectedOrgId !== 1 &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_security_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_security_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={true}
                                                disabled={true}
                                                value="checkBankId"
                                            />
                                        }
                                        label={getTranslation('organization_info_cart_auth_type_bankid')}
                                    />
                                    {(getPerm() > 2 || TFA) &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={TFA}
                                                    disabled={getPerm() < 3}
                                                    value="checkTwofactor"
                                                    onChange={(e) => {
                                                        setTFA(e.target.checked);
                                                        saveAuthTypeChanges(e.target.checked)
                                                    }}
                                                />
                                            }
                                            label={getTranslation('organization_info_cart_auth_type_mobile_login')}
                                        />
                                    }
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {prodManaging &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_infosolutions_sync_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_infosolutions_sync_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={infoSolutions.open}
                                                onChange={() => toogleInfoSolutions()}
                                                value="infosolutions_sync"
                                            />
                                        }
                                        label={getTranslation('my_page_infosolutions_sync_check_label')}
                                    />
                                </FormGroup>
                            </Grid>
                            {infoSolutions.open &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="filled"
                                            label={getTranslation('my_page_infosolutions_sync_customer_id')}
                                            name='customer_id'
                                            value={infoSolutions.customerId}
                                            onChange={(e) => handleInfoSolutionsChange(selectedOrgId, e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={infoSolutions.autoSync}
                                                        onChange={(e) => handleInfoSolutionsChange(selectedOrgId, e)}
                                                        value="infosolutions_auto_sync"
                                                        name="auto_sync"
                                                    />
                                                }
                                                label={getTranslation('my_page_infosolutions_auto_sync_check_label')}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                    </Paper>
                }
                {prodManaging &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_wacom_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_wacom_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={wacomCheck}
                                                onChange={() => toogleWacom(selectedOrgId)}
                                                value="wacom"
                                            />
                                        }
                                        label={getTranslation('my_page_wacom_check_label')}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {(Number(selectedOrg.id) === 1 && prodManaging) &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_patient_section_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_patient_section_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Number(data.patientmodule) === 1}
                                                onChange={togglePatient}
                                                value="hide_patient"
                                            />
                                        }
                                        label={getTranslation('my_page_patient_section_check_label')}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {Number(selectedOrg.id) !== 1 && selectedOrg.admin &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_patient_section_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_patient_section_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCustomerHidePatientClick}
                                    disabled={Number(data.patientmodule) === 0}
                                >
                                    {getTranslation("my_page_hide_patient_button")}
                                </Button>
                            </Grid>
                            {Number(data.patientmodule) === 0 &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        {getTranslation('my_page_patient_section_contact_prodiagnostics_text')}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Paper>
                }
                {Number(selectedOrgId) !== 1 &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation("my_page_select_country_title")}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_select_country_text')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{marginLeft: -10}}>
                                <FormControl className='fixSelectAndDates'>
                                    <InputLabel
                                        id="newLang">{getTranslation('change_country_select_label')}</InputLabel>
                                    <Select

                                        labelId="newCountry"
                                        value={data.ssn_type}
                                        name='ssn_type'
                                        onChange={handleChange}
                                    >
                                        {countries.map(al =>
                                            <MenuItem key={al.value} value={al.value}>{al.title}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} style={{display: 'flex', alignItems: 'flex-end', padding: '10px 0'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveCountry}
                                >{getTranslation('generic_button_save')}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                <Paper className={classes.paperTitle} elevation={1}>
                    <Grid container style={{marginBottom: 30}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                {getTranslation('my_page_personal_settings_title')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {getTranslation('my_page_personal_settings_subtitle')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4} style={{marginLeft: -10}}>
                            <FormControl className='fixSelectAndDates'>
                                <InputLabel id="newLang">{getTranslation('profile_change_language_label')}</InputLabel>
                                <Select

                                    labelId="newLang"
                                    value={data.newLang || lang}
                                    name='newLang'
                                    onChange={handleChange}
                                >
                                    {availableLangs.map(al =>
                                        <MenuItem key={al.value} value={al.value}>{al.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} style={{display: 'flex', alignItems: 'flex-end', padding: '10px 0'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitData}
                            >{getTranslation('generic_button_save')}</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.fakeLink} variant="body2" gutterBottom onClick={(e) => {
                                setOpen('1')
                            }}>
                                {getTranslation('my_page_password_change')}<Launch style={{marginLeft: 5}}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                {((getPerm(selectedOrg) >= 2 || selectedOrg.admin) && !selectedOrg.mro && !selectedOrg.consult) &&
                    <Paper className={classes.paperTitle} elevation={1}>
                        <Grid container style={{marginBottom: 30}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                                    {getTranslation('my_page_table_staff_title')}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {getTranslation('my_page_table_staff_subtitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <PersonelTable id={selectedOrgId} {...props} />
                    </Paper>
                }
            </Grid>
            {open &&
                <ProfileDialog
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>{open === '1' ? getTranslation('my_page_change_password_modal_title') : getTranslation('my_page_change_address_modal_title')}</DialogTitle>
                    <DialogContent>
                        {open === '1' && <Profile closeDialog={() => setOpen(false)}/>}
                        {open === '2' && <Address closeDialog={() => setOpen(false)}/>}
                    </DialogContent>
                </ProfileDialog>
            }
        </Grid>
    );
};
export default OrganizationPage;
